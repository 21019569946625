const html = require('choo/html')
const { Layout, GlobalStatus, SystemStatus } = require('../components/index')

module.exports = view
let title = 'Status'
let brand = 'PANTARIS'

function view (state, emit) {
  title = `${state.config.brand} ${state.config.title}`
  if (`${state.config.brand} ${state.config.title}` !== `${brand} ${title}`) {
    emit(state.events.DOMTITLECHANGE, `${state.config.brand} ${state.config.title}`)
  }

  const layout = new Layout('layout')

  return layout.render({
    config: state.config,
    children: html`
    <div class="mt3 center w-100">
      <div class="flex flex-column ">
        <div class="mb3">
          ${state.cache(GlobalStatus, 'global-status').render({ globalStatus: state.globalStatus, heartbeat: state.heartbeat })}
        </div>
        <div class="bt bl br b--light-gray">
          ${Object.values(state.systems).map(system => state.cache(SystemStatus, system.id).render(system))}
        </div>
      </div>
    </div>
  `
  })
}
