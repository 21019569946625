require('core-js/stable')
const css = require('sheetify')
const choo = require('choo')
const sse = require('choo-sse')

css('tachyons')
css('./style/fonts.css')
css('./style/variables.css')
css('./style/animations.css')
css('./style/style.css')
css('./style/icons.css')

const app = choo()
if (process.env.NODE_ENV !== 'production') {
  app.use(require('choo-devtools')())
} else {
  app.use(require('choo-service-worker')())
}

app.use(sse('/sse'))
app.use(require('./stores/status'))

app.route('/', require('./views/main'))
app.route('/*', require('./views/404'))

module.exports = app.mount('body')
