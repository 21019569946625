const severity = {}

// operational: All fine!
const OPERATIONAL = 'operational'
severity[OPERATIONAL] = {
  value: 10,
  title: 'Operational',
  global: {
    bgColor: 'bg-green',
    color: 'dark-gray'
  },
  system: {
    color: 'green'
  },
  icon: 'cal-Icon--alert-success'
}

// under-maintenance: The system(s) cannot handle requests due to a temporary maintenance update.
const UNDER_MAINTENANCE = 'under-maintenance'
severity[UNDER_MAINTENANCE] = {
  value: 5,
  title: 'Under maintenance',
  global: {
    bgColor: 'bg-blue',
    color: 'dark-gray'
  },
  system: {
    color: 'blue'
  },
  icon: 'cal-Icon--problem-frame'
}

// major-outage: No one can access the system(s) because it is completely down.
const MAJOR_OUTAGE = 'major-outage'
severity[MAJOR_OUTAGE] = {
  value: 0,
  title: 'Major outage',
  global: {
    bgColor: 'bg-red',
    color: 'dark-gray'
  },
  system: {
    color: 'red'
  },
  icon: 'cal-Icon--alert-error-filled'
}

// partial-outage: Limited access to the system(s), because it's probably experiencing difficulties.
const PARTIAL_OUTAGE = 'partial-outage'
severity[PARTIAL_OUTAGE] = {
  value: 1,
  title: 'Partial outage',
  global: {
    bgColor: 'bg-orange',
    color: 'dark-gray'
  },
  system: {
    color: 'orange'
  },
  icon: 'cal-Icon--alert-warning-filled'
}

// degraded-performance: The system(s) is not stable, it's working slow otherwise impacted in a minor way.
const DEGRADED_PERFORMANCE = 'degraded-performance'
severity[DEGRADED_PERFORMANCE] = {
  value: 2,
  title: 'Degraded performance',
  global: {
    bgColor: 'bg-yellow',
    color: 'dark-gray'
  },
  system: {
    color: 'yellow'
  },
  icon: 'cal-Icon--alert-warning'
}

const severityArray = Object.keys(severity)

// Event names
const SYSTEM_STATUS = 'system-status'
const GLOBAL_STATUS = 'global-status'
const INIT_STATUS = 'init-status'
const HEARTBEAT = 'heartbeat'

module.exports = {
  severity,
  severityArray,
  statusToValue: (s) => {
    if (severity[s]) {
      return severity[s].value
    } else {
      throw new Error('Invalid status ' + s)
    }
  },
  statusFromValue: (v) => {
    const filter = severityArray.filter(s => severity[s] && severity[s].value === v).map(s => s)
    if (filter.length === 1) {
      return filter[0]
    }
    throw new Error('Invalid status value ' + v)
  },
  OPERATIONAL,
  UNDER_MAINTENANCE,
  MAJOR_OUTAGE,
  PARTIAL_OUTAGE,
  DEGRADED_PERFORMANCE,
  events: {
    SYSTEM_STATUS,
    GLOBAL_STATUS,
    INIT_STATUS,
    HEARTBEAT
  }
}
