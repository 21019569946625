const html = require('choo/html')
const Component = require('choo/component')
const { severity } = require('../../core/status')

module.exports = class GlobalStatus extends Component {
  constructor (id, state, emit) {
    super(id)
    this.local = state.components[id] = {}
  }

  createElement (state) {
    this.status = state.globalStatus
    this.playBeat = this.heartbeat !== state.heartbeat ? !this.playBeat : false
    this.heartbeat = state.heartbeat
    const currentSeverity = severity[this.status]
    return html`
      <div class='flex near-white justify-center items-center pa3 fw5 f3 smooth-background ${this.playBeat ? 'heartbeat' : 'heartbeat animation-pause'} ${currentSeverity.global.color} ${currentSeverity.global.bgColor}'>
        <span>${currentSeverity.title}</span>
        <span class='cal-Icon ${currentSeverity.icon} ml2'></span>
      </div>
    `
  }

  update (state) {
    return this.globalStatus !== state.globalStatus || this.heartbeat !== state.heartbeat
  }
}
