const html = require('choo/html')

module.exports = view

function view (state, emit) {
  const TITLE = `${state.config.brand} ${state.config.title} - route not found`

  if (state.title !== TITLE) emit(state.events.DOMTITLECHANGE, TITLE)
  return html`
    <div class="sans-serif pa3">
      <h1>Route not found.</h1>
      <a class="pt2" href="/">Back to main.</a>
    </div>
  `
}
