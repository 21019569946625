const html = require('choo/html')
const Component = require('choo/component')
const { severity } = require('../../core/status')

module.exports = class SystemStatus extends Component {
  constructor (id, state, emit) {
    super(id)
    this.system = state.components[id] = {}
  }

  createElement (system) {
    this.system = system
    this.status = system.status
    const systemSeverity = severity[this.status]

    const title = () => {
      if (!system.showUrl) {
        return html`
          <span class='f5'>${system.title}</span>
        `
      }
      return html`
        <span class='f5'><a target='_blank' href='${system.url}'>${system.title}</a></span>
      `
    }
    return html`
      <div id=${system.id} class='flex justify-between items-center ph3 pv2 bb b--light-gray'>
        <div class='flex items-center justify-between w-50'>
         ${title()}
        </div>
        <div data-severity class='flex items-center ${systemSeverity.system.color}'>
          <span class='f5'>${systemSeverity.title}</span>
          <span class='cal-Icon ${systemSeverity.icon} ml2' title='${system.title}: ${systemSeverity.title} - Ping: ${system.duration}'></span>
        </div>
      </div>
    `
  }

  update (system) {
    return this.system !== system
  }
}
