const _status = require('../../core/status')

module.exports = store

const initialState = {
  systems: {},
  globalStatus: _status.UNDER_MAINTENANCE,
  heartbeat: 0,
  title: '',
  config: {
    brand: 'PANTARIS',
    title: 'Status',
    short_title: 'status-choo',
    description: 'Loading...',
    corporation: 'ETAS',
    git: {
      url: '',
      commit: ''
    },
    baseUrl: '/',
    defaultLocale: 'en',
    locales: [
      { code: 'en', iso: 'en-US', name: 'English' }
    ],
    links: {}
  }
}

/**
 *
 * @param {initialState} state
 * @param {*} emitter
 */
function store (state, emitter) {
  Object.assign(state, initialState)

  emitter.on('DOMContentLoaded', () => {
    emitter.on('sse:open', msg => {
      emitter.emit('log:debug', msg)
    })
    emitter.on('sse:init', msg => {
      emitter.emit('log:debug', msg)
    })

    emitter.on('sse:message', (msg) => {
      let eventObject = JSON.parse(msg.data)
      if (eventObject.length > 0) {
        eventObject = JSON.parse(eventObject[0])
      }
      if (!eventObject.event) {
        emitter.emit('log:error', {
          message: 'Failed to parse message',
          msg
        })
      }
      if (eventObject.event === _status.events.INIT_STATUS) {
        // Initialize status on first connection or config reload -> directly assign the server state
        Object.assign(state, eventObject.state)
        emitter.emit(state.events.RENDER)
      } else if (eventObject.event === _status.events.SYSTEM_STATUS) {
        // Single system status update
        state.systems[eventObject.system.id] = eventObject.system
        emitter.emit(state.events.RENDER)
      } else if (eventObject.event === _status.events.GLOBAL_STATUS) {
        // Global status update
        state.globalStatus = eventObject.status
        emitter.emit(state.events.RENDER)
      } else if (eventObject.event === _status.events.HEARTBEAT) {
        // Global heartbeat
        state.heartbeat = eventObject.time
        emitter.emit(state.events.RENDER)
      } else {
        emitter.emit('log:error', { eventObject })
      }
    })

    emitter.on('sse:error', err => {
      emitter.emit('log:error', err)
    })
  })
}
