const html = require('choo/html')
const Component = require('choo/component')

module.exports = class Layout extends Component {
  constructor (id, state, emit) {
    super(id)
    this.children = state
    this.state = state
  }

  createElement ({ children, config }) {
    this.children = children
    const renderLink = (link) => {
      if (link.type === 'mail') {
        return (html`
        <a class='ml0 ml3-m ml3-l mt3 mt0-m mt0-l' href=${link.url}>${link.title}</a>`)
      } else if (link.type === 'link') {
        return (html`
        <a class='ml0 ml3-m ml3-l mt3 mt0-m mt0-l' target=_blank href=${link.url}>${link.title}</a>`)
      } else {
        return html`<span class='ml0 ml3-m ml3-l mt3 mt0-m mt0-l'>Invalid link type</span>`
      }
    }

    return html`
    <body class='etas-sans'>
      <main class='flex flex-column justify-between'>
        <div class='container'>
          <div class='center w-100 w-90-ns'>
            <header class='flex flex-column flex-row-l items-center mt3 justify-center justify-between-l'>
              <div class='ph3-l mr2-l flex justify-center justify-start-l'>
                <a href='/' title='${config.brand} by ${config.corporation}'>
                  <img class='logo' src='assets/logo.svg' alt='${config.corporation} Logo'>
                </a>
              </div>
              <div class='ph3-l ml2-l justify-end-l'>
                <h1 class='dib lh-title fw1 dark-gray tr mv0'>
                  <span class='fw6'>${config.brand}</span>
                  <span>${config.title}</span>
                </h1>
                <div class='f4 tr-l tc'>
                  ${process.env.NODE_ENV === 'development' ? html`<small class='db'>Development</small>` : html`<small class='db'>${config.domain}</small>`}
                </div>
              </div>
            </header>
          </div>
          <div class='center w-100 w-90-ns ph0 ph3-ns mb3'>
            ${this.children}
          </div>
        </div>
        <footer class='footer'>
          <div class='container ph3-l pv3'>
            <div class='flex flex-column flex-row-l items-center justify-between-l w-100 w-90-ns center'>
              <div class='flex flex-column flex-row-l items-center justify-start-l'>
                <span class='mr3'>ÃÂ© ${(new Date()).getFullYear()} <a href='https://platform.${config.domain}/corporate-information'>${config.corporation}</a></span>
              </div>
              <div class='flex flex-column flex-row-m flex-row-l items-center mt0 mt3-m mt0-l'>
                ${Object.values(config.links).filter(Boolean).map(renderLink)}
              </div>
            </div>
          </div>
        </footer>
      </main>
    </body>
  `
  }

  update (children) {
    return this.children !== children
  }
}
